<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="2">
        <v-select
          solo
          v-model="month"
          :items="months"
          :menu-props="{ maxHeight: '400' }"
          label="Month"
          hint="Please select month"
          persistent-hint
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          solo
          v-model="year"
          :items="years"
          :menu-props="{ maxHeight: '400' }"
          label="Year"
          hint="Please select year"
          persistent-hint
        ></v-select>
      </v-col>
      <v-col cols="5">
        <v-select
          solo
          v-model="selectedStatuses"
          :items="statusList"
          item-text="label"
          item-value="id"
          chips
          multiple
          :menu-props="{ maxHeight: '400' }"
          label="Statuses"
          hint="Please select invoice statuses"
          persistent-hint
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-btn
          dark
          class="button greeny text-button"
          style="height:46px;width:100%"
          @click="reportInvoiceExcel"
          hint="Create Excel report"
        >
          Create Report
          <v-icon style="margin-left: 5px">
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <InvoiceTable :status="2" :headers="headers" :selectedTenants="[]" :tenants="[]"
                  :justMount="true" :updatedStatus="updatedStatus"
                  @onUpdate="updatedStatus = $event"/>

    <InvoiceTable :status="3" :headers="headers" :selectedTenants="[]" :tenants="[]"
                  :justMount="true" :updatedStatus="updatedStatus"
                  @onUpdate="updatedStatus = $event"/>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";
import InvoiceTable from "@/views/Company/Invoice/components/InvoiceTable";

export default {
  components: {
    InvoiceTable
  },
  data: () => ({
    updatedStatus: null,
    loading: false,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    selectedStatuses: [],
    years: [],
    headers: [
      {
        text: "Order ID",
        value: "orderId",
        width: 200,
      },
      {
        text: "Linkonline IDs",
        value: "bookings",
        width: 120,
      },
      {
        text: "Invoice Number",
        value: "fortnoxNumber",
        width: 130
      },
      {
        text: "Company",
        value: "company.name",
        width: 180
      },
      {
        text: "Customer",
        value: "customer.firstName",
        width: 220,
      },
      {
        text: `Order Debited (GMT +${moment().utcOffset() / 60})`,
        value: "created",
        width: 180,
      },
      // {
      //   text: "Debited By",
      //   value: "debitedBy",
      //   width: 160,
      // },
      {
        text: `Must Be Paid Before`,
        value: "mustBePaidDate",
        width: 155,
        sortable: false
      },
      {
        text: "Amount",
        value: "order.calculatedPrice.total.plainText",
        align: "right",
        width: 120,
        sortable: false
      },
      {
        text: "Actions",
        value: "actions",
        align: "right",
        width: 130,
        sortable: false
      },
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    statusList: [
      {
        label: "Pending",
        id: 0
      },
      {
        label: "Invoiced",
        id: 1
      },
      {
        label: "Paid",
        id: 2
      },
      {
        label: "Accounted",
        id: 3
      }
    ],
    invoices: []
  }),
  mounted() {
    const year = new Date().getFullYear();
    const month = moment().month();

    const years = [year];

    for (let i = 0; i < 25; i++) {
      years.push(years[i] - 1)
    } // prettier-ignore

    this.month = this.months[month];
    this.years = years;
    this.selectedStatuses = this.statusList.slice(2).map(status => status.id);
  },
  methods: {
    async reportInvoiceExcel() {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      try {
        await this.$store.dispatch("loading", true);
        const data = await Trainplanet.reportInvoiceExcel({
          month: this.months.indexOf(this.month) + 1,
          year: this.year,
          statuses: this.selectedStatuses
        });

        const objectUrl = URL.createObjectURL(data.data);

        a.href = objectUrl;
        a.download = data.headers['content-disposition'].split('"')[1];
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      } catch (error) {
        const err = await error.response.data.text();
        const message = JSON.parse(err).message;
        await this.$store.dispatch("error", message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
